import React from "react";
import cloud from "./images/bg-cloud.png";
import icon1 from "./images/Group 21.svg";
import icon2 from "./images/Group 22.svg";
import circle from "./images/circle.png";
const Company = () => {
  return (
    <div
      className="w-full h-full relative text-4xl  pt-2 font-semibold"
      style={{
        backgroundImage: `url(${cloud})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="absolute inset-0 bg-gray-500/30"></div>
      <div className="grid md:grid-cols-2 justify-between items-center relative z-10">
        <div className="ps-[2%] md:ps-[10%] grid grid-rows-2">
          <div className="grid gap-2 pt-6">
            <h1 className="text-[25px] font-semibold">About our company</h1>
            <h1 className="text-[34px]  leading-[40px] font-bold">
              Consultancy Division For <br></br> Business Professionals
            </h1>
            <h1 className="text-[15px] font-semibold leading-5">
              New Guide Consultancy Is The Platform To Help You, Set Up Your
              Business. Right From Incorporation, Registration And Licensing To
              Establishing Your Office Space, We Facilitate The Formation Of
              Your Company Through Expert Counseling And <br></br>Incubation.
            </h1>
          </div>
          <div className="flex items-center gap-2">
            <div>
              <img src={icon1} alt="icon1" className="w-[150px] " />
            </div>
            <div className="grid gap-2">
              <h1 className="text-[30px] font-semibold">Company Marketing</h1>
              <h1 className="text-[14px] leading-6">
                STRONG GLOBAL PRESENCE, COMMITMENT TO HIGH-QUALITY,
                PROFESSIONALISM, BELIEF IN ESTABLISHING LONG TERM RELATIONSHIPS
                WITH OUR CLIENTS"
              </h1>
            </div>
          </div>
          <div className="flex items-center gap-2">
            <div>
              <img src={icon1} alt="icon1" className="w-[150px] h-[150px]" />
            </div>
            <div className="grid gap-2">
              <h1 className="text-[30px] font-semibold">Company Marketing</h1>
              <h1 className="text-[14px] leading-6">
                STRONG GLOBAL PRESENCE, COMMITMENT TO HIGH-QUALITY,
                PROFESSIONALISM, BELIEF IN ESTABLISHING LONG TERM RELATIONSHIPS
                WITH OUR CLIENTS"
              </h1>
            </div>
          </div>
        </div>
        <div>
          <img src={circle} alt="circle" className="w-[50%] mx-auto my-10" />
        </div>
      </div>
    </div>
  );
};

export default Company;

import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault(); // This prevents the default form submission
    const whatsappLink = `https://wa.me/971525453323?text=Name: ${formData.name}%0aPhone: ${formData.phone}%0aEmail: ${formData.email}%0aMessage: ${formData.message}`;
    window.open(whatsappLink, '_blank');
  };

  const location = useLocation()

  return (
    <div className="z-20 w-[80vw] md:w-[35vw] bg-white rounded-2xl border border-black mx-auto p-4">
      
      <form className="space-y-4" onSubmit={handleSubmit}>

        {
        location.pathname === "/consulting_data" &&
            <label className='text-2xl font-bold text-blue-800' >Consulting Data</label>
        }

        {/* Name */}
        <section className='flex'>
          <div className='mr-10'>
            <label htmlFor="name" className="block text-sm font-medium text-gray-700">Your Name</label>
            <input
              type="text" id="name" name="name" value={formData.name} onChange={handleChange} required
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md bg-gray-200"  placeholder="Enter your name"
            />
          </div>

          {/* Phone */}
          <div>
            <label htmlFor="phone" className="block text-sm font-medium text-gray-700">Your Phone</label>
            <input
              type="tel" id="phone" name="phone" value={formData.phone} onChange={handleChange} required
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md bg-gray-200" placeholder="Enter your phone number"
            />
          </div>
        </section>

        {/* Email */}
        <div>
          <label htmlFor="email" className="block text-sm font-medium text-gray-700">Your Mail</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange} required
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md bg-gray-200"
            placeholder="Enter your email"
          />
        </div>

        {/* Message */}
        <div>
          <label htmlFor="message" className="block text-sm font-medium text-gray-700">Message</label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange} required
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md bg-gray-200" 
            rows="4"
            placeholder="Enter your message"
          ></textarea>
        </div>

        {/* WhatsApp Now Button */}
        <div className="flex justify-center">
          <button
            type="submit"  // Make this a form submit button
            className="bg-green-500 text-white py-2 px-4 rounded-md hover:bg-green-600 transition"
          >
            WhatsApp Now
          </button>
        </div>
      </form>
    </div>
  );
};

export default Contact;

import React from "react";

import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import bg from "../Counsling_data/images/izuddin-helmi-adnan-1e71PSox7m8-unsplash.png";
import img1 from "../BusinessService/images/Group 67.svg";
import img2 from "../BusinessService/images/Group 73.svg";
import img3 from "../BusinessService/images/Group 80.svg";
import img4 from "../BusinessService/images/Group 82.svg";
import img5 from "../BusinessService/images/Group 90.svg";
import img6 from "../BusinessService/images/Group 96.svg";
import img7 from "../BusinessService/images/Group 162.png";

const Online = () => {

  const whatsapp = () => {
    window.open('https://wa.me/971525453323', '_blank')
  }

  return (
    <div>
      <div>
        <div
          className="w-full h-[38vh] relative "
          style={{
            backgroundImage: `url(${bg})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="absolute inset-0 bg-black opacity-50 h-full w-full"></div>
          <div className="flex flex-col justify-center items-center gap-4 h-full text-white relative z-10">
            <h1 className="text-3xl font-bold">Online Approvals</h1>
            <div className="flex items-center gap-4 font-semibold text-xl">
              <div>
                <h1>Home</h1>
              </div>
              <div>
                <MdKeyboardDoubleArrowRight />
              </div>
              <div>
                <h1>Online Approvals</h1>
              </div>
            </div>
          </div>
        </div>
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6  w-full my-7 px-6">

          <div className="flex flex-col border border-gray-500 rounded-3xl shadow-2xl px-8  py-5">
            <img src={img5} className="w-[50px]" alt="img1" />
            <h1 className="text-[#254E94] text-2xl font-semibold">
              Dubai Municipality
            </h1>
            <p className="text-base pt-4 pb-7">
            Dubai is one of the leading international financial centres and among one of the 
            fastest growing cities in the world.
            </p>
            <button onClick={whatsapp} className="text-black font-bold bg-gray-400 border w-40 py-3 px-2 border-black rounded-s-full rounded-e-full">
              Whatsapp Now
            </button>
          </div>

          <div className="flex flex-col border border-gray-500 rounded-3xl shadow-2xl px-8  py-5">
            <img src={img2} className="w-[50px]" alt="img1" />
            <h1 className="text-[#254E94] text-2xl font-semibold">
              Civil Aviation
            </h1>
            <p className="text-base pt-4 pb-7">
            We offer comprehensive business formation services to help investors and entrepreneurs establish.
            </p>
            <button onClick={whatsapp} className="text-black font-bold bg-gray-400 border w-40 py-3 px-2 border-black rounded-s-full rounded-e-full">
              Whatsapp Now
            </button>
          </div>
          <div className="flex flex-col border border-gray-500 rounded-3xl shadow-2xl px-8  py-5">
            <img src={img3} className="w-[50px]" alt="img1" />
            <h1 className="text-[#254E94] text-2xl font-semibold">
              Civil Defence
            </h1>
            <p className="text-base pt-4 pb-7">
            Civil Defense in Dubai plays a pivotal role in ensuring the safety and security of the city's residents and infrastructure. As a leading document clearance company, New Guide recognizes 
            the significance of Civil Defense's efforts in safeguarding the community.
            </p>
            <button onClick={whatsapp} className="text-black font-bold bg-gray-400 border w-40 py-3 px-2 border-black rounded-s-full rounded-e-full">
              Whatsapp Now
            </button>
          </div>
          <div className="flex flex-col border border-gray-500 rounded-3xl shadow-2xl px-8  py-5">
            <img src={img4} className="w-[50px]" alt="img1" />
            <h1 className="text-[#254E94] text-2xl font-semibold">
                Dubai Customs
            </h1>
            <p className="text-base pt-4 pb-7">
              We help organizations across, private, public and social sectors create on the change that matters the most to them 
            </p>
            <button onClick={whatsapp} className="text-black font-bold bg-gray-400 border w-40 py-3 px-2 border-black rounded-s-full rounded-e-full">
              Whatsapp Now
            </button>
          </div>
          <div className="flex flex-col border border-gray-500 rounded-3xl shadow-2xl px-8  py-5">
            <img src={img1} className="w-[50px]" alt="img1" />
            <h1 className="text-[#254E94] text-2xl font-semibold">
              Dubai Sports Council
            </h1>
            <p className="text-base pt-4 pb-7">
              New Guide supports businesses in achieving their goals efficiently. One prominent entity shaping Dubai's landscape
              is Dubai Sports Council (DSC). Established to develop and foster sports culture in the emirate
            </p>
            <button onClick={whatsapp} className="text-black font-bold bg-gray-400 border w-40 py-3 px-2 border-black rounded-s-full rounded-e-full">
              Whatsapp Now
            </button>
          </div>
          <div className="flex flex-col border border-gray-500 rounded-3xl shadow-2xl px-8  py-5">
            <img src={img7} className="w-[50px]" alt="img1" />
            <h1 className="text-[#254E94] text-2xl font-semibold">
              SIRA & MOH
            </h1>
            <p className="text-base pt-4 pb-7">
            Our flagship business publication Quarterly, has been defining and informing the senior management agenda.
            </p>
            <button onClick={whatsapp} className="text-black font-bold bg-gray-400 border w-40 py-3 px-2 border-black rounded-s-full rounded-e-full">
              Whatsapp Now
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Online;

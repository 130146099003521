import React from "react";
import img1 from "./images/Group 55.svg";
import img2 from "./images/Group 264 (1).svg";
import img3 from "./images/Group 265 (1).svg";
import img4 from "./images/Group 266 (1).svg";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import bg from "../Counsling_data/images/izuddin-helmi-adnan-1e71PSox7m8-unsplash.png";
import Contact from "./Contact";

const ContactUs = () => {
  return (
    <div>
      {/* Header Section */}
      <div
        className="w-full h-[38vh] relative"
        style={{
          backgroundImage: `url(${bg})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="absolute inset-0 bg-black opacity-50 h-full w-full"></div>
        <div className="flex flex-col justify-center items-center gap-4 h-full text-white relative z-10">
          <h1 className="text-3xl font-bold">Contact US</h1>
          <div className="flex items-center gap-4 font-semibold text-xl">
            <div>
              <h1>Home</h1>
            </div>
            <div>
              <MdKeyboardDoubleArrowRight />
            </div>
            <div>
              <h1>Contact Us</h1>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content Section */}
      <div
        style={{
          backgroundImage: `url(${bg})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
        className="relative w-full h-full pb-20 text-black"
      >
        {/* Background white overlay */}
        <div className="absolute inset-0 bg-white opacity-90 z-0 "></div>

        {/* Content with higher z-index */}
        <div className="container grid md:grid-cols-2 mx-auto gap-8 sm:gap-0 relative z-10 pt-10">
          <div className="flex justify-end w-full items-center">
            <img src={img1} alt="img" className="md:w-auto w-[300px]" />
          </div>
          <div className="flex flex-col md:gap-5 gap-10 md:items-start items-center">
            <div className="bg-white flex gap-3 border border-black rounded-2xl px-4 pt-2 pb-10 w-[60%]  font-medium relative z-20">
              <div>
                <img src={img4} alt="img2" className="w-[25px] h-[25px] md:w-[35px] md:h-[35px]" />
              </div>
              <div className="grid">
                <h1>Phone No:</h1>
                <p>00971525453323</p>
              </div>
            </div>
            <div className="bg-white flex gap-3 border border-black rounded-2xl px-4 pt-2 pb-10 w-[60%] md:text-lg font-medium relative z-20">
               
                <img src={img2} alt="img2" className="w-[25px] h-[25px] md:w-[35px] md:h-[35px]" />
              
              <div className="flex flex-col w-40 h-20">
                <h1>Email:</h1>
                <p  >Newguideb2b@gmail.com</p>
              </div>
            </div>
            <div className="bg-white flex gap-3 border border-black rounded-2xl px-4 pt-2 pb-10 w-[60%] font-medium relative z-20">
              <div>
                <img src={img3} alt="img2" className="w-[25px] h-[25px] md:w-[35px] md:h-[35px]" />
              </div>
              <div className="grid">
                <h1>Location:</h1>
                <p>
                  Shop S20, Arzoo Building Next to Al Twar Center Al Qusais,
                  Dubai-UAE
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Get in touch section */}
        <div className="grid justify-center text-center text-black gap-2 pt-20 font-bold relative z-10">
          <div className="flex items-center justify-center gap-5 font-bold">
            <div className="w-28 h-1 bg-black"></div>
            <h1 className="text-[10px] md:text-[40px] font-semibold">
              GET IN TOUCH
            </h1>
            <div className="w-28 h-1 bg-black"></div>
          </div>
          <h1 className="text-[20px] md:text-[40px] leading-[70px] font-bold">
            FEEL FREE TO CONTACT WITH US ANY TIME.{" "}
          </h1>
        </div>

        {/* Contact form and map */}
        <div className="grid lg:grid-cols-2 gap-10 lg:gap-0 justify-center items-center relative z-10">
          {/* Contact form */}
          <Contact />

          {/* Google map */}
          <div className="relative z-10">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3608.187998276337!2d55.38271527499527!3d25.26426052898182!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f5daf372d33c9%3A0x56ffe8b35b0dfd98!2sARZOO%20BUILDING!5e0!3m2!1sen!2sae!4v1726320462794!5m2!1sen!2sae"
              width="500"
              height="450"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              className="rounded-3xl sm:w-full w-[100%]"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;

import React from "react";
import bg from "./images/soran-ali-rJ9VlBsmidE-unsplash.png";
import logo from "./images/Logo.png";
import { MdKeyboardArrowRight } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { MdLocationPin } from "react-icons/md";
import { Link } from "react-router-dom";

const Footer = () => {

  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' 
    });
  }

  return (
    <div
      style={{
        backgroundImage: `url(${bg})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
      className="relative w-full  h-full  pb-20"
    >
      <div className="relative z-40 grid justify-center text-center text-white gap-2 pt-20 ">
        <div className="flex items-center justify-center">
          <div className="w-20 h-1 bg-white"></div>
          <h1 className="text-[10px] md:text-[20px] p-1 lg:text-[20px] font-semibold">
            OUR PARTNER
          </h1>
          <div className="w-20 h-1 bg-white"></div>
        </div>
        <h1 className="text-[20px] md:text-[30px]  leading-[70px] font-bold">
          UAE GOVT BODIES WE WORK WITH
        </h1>
        <h1 className="text-[10px] md:text-[12px] font-semibold leading-5">
          Ur Consultants Include Medical Doctors, Engineers, Designers, Data{" "}
          <br></br> Scientists, Business Managers, Civil Servants,
          Entrepreneurs, And <br></br> Research Scientists.
        </h1>
      </div>
      <div className="absolute inset-0 bg-black opacity-50 z-10 "></div>
      <div className="md:ps-0 ps-[5%] md:flex text-white ">
        <div className="relative z-40  md:mx-auto w-[30%] text-[15px] md:text-xl font-semibold">
          {/* <div className="absolute top-[-30%] lg:top-[-70%] xl:top-[-70%]">
          </div> */}
            <img src={logo} alt="logo" className="lg:w-[140px] md:w-[100px]" />
          <p className="pt-10 w-[70vw] md:w-[24vw] text-[16px] pl-0 md:pl-6">
            We Are The Platform To Help You Set Up Your Business. Right
            From Incorporation, Registration And Licensing
            To Establishing Your Office Space.
          </p>
        </div>
        <div className="grid md:grid-cols-4 justify-center md:justify-start md:gap-4 lg:gap-14 xl:gap-28 md:pe-9 pe-0">


          <div className="relative flex flex-col  gap-7 z-40">
           
        
             <div className="w-full font-bold pt-5">
              <h1>QUICK LINKS</h1>
              <div className="absolute w-full">
                <div className="h-[3px] m-1 w-[20%] md:w-[50%] bg-blue-500"></div>
                <div className=" md:h-[3px] bg-white"></div>
              </div>
            </div>

            <div className=" flex" >
              <MdKeyboardArrowRight className="text-2xl" />
              <Link to="/" onClick={handleClick} >Home</Link>
            </div>

            <div className=" flex" >
              <MdKeyboardArrowRight className="text-2xl" />
              <Link to="/about_us" onClick={handleClick} >About Us</Link>
            </div>

            <div className=" flex" >
              <MdKeyboardArrowRight className="text-2xl" />
              <Link to="/contact_us" onClick={handleClick} >Contact Us</Link>
            </div>             
           
          </div>


          <div className="relative flex flex-col gap-7 z-50">
            <div className="pt-5 w-full font-bold">
              <h1>Main Services</h1>
              <div className="absolute w-full">
                <div className="h-[3px] w-[20%] md:w-[50%] bg-blue-500"></div>
                <div className=" md:h-[3px] bg-white"></div>
              </div>
            </div>
            <div className="flex font-normal">
              <div>
                <MdKeyboardArrowRight className="text-2xl" />
              </div>
              <div>
                <Link onClick={handleClick} to="/business_services">Business Services</Link>
              </div>
            </div>
            <div className="flex font-normal">
              <div>
                <MdKeyboardArrowRight className="text-2xl" />
              </div>
              <div>
                <Link onClick={handleClick} to="/visa">Visa Services</Link>
              </div>
            </div>
            <div className="flex font-normal">
              <div>
                <MdKeyboardArrowRight className="text-2xl" />
              </div>
              <div>
                <Link onClick={handleClick} to="/online">Online Approach</Link>
              </div>
            </div>
            <div className="flex font-normal">
              <div>
                <MdKeyboardArrowRight className="text-2xl" />
              </div>
              <div>
                <Link onClick={handleClick} to="/pro">Pro Service</Link>
              </div>
            </div>
          </div>
          <div className="relative flex flex-col gap-7 z-40">
            <div className="pt-5 w-full font-bold">
              <h1>Documentation</h1>
              <div className="absolute w-full">
                <div className="h-[3px] w-[20%] md:w-[50%] bg-blue-500"></div>
                <div className=" md:h-[3px] bg-white"></div>
              </div>
            </div>
            <div className="flex font-normal text-white">
              <div>
                <MdKeyboardArrowRight className="text-2xl" />
              </div>
              <div>
                <Link onClick={handleClick} to="/financial">Financial Services</Link>
              </div>
            </div>
            <div className="flex font-normal">
              <div>
                <MdKeyboardArrowRight className="text-2xl" />
              </div>
              <div>
                <Link onClick={handleClick} to="/tax">Tax Consultation</Link>
              </div>
            </div>
            <div className="flex font-normal">
              <div>
                <MdKeyboardArrowRight className="text-2xl" />
              </div>
              <div>
                <Link onClick={handleClick} to="/attestation">Attestation Service</Link>
              </div>
            </div>
            <div className="flex font-normal">
              <div>
                <MdKeyboardArrowRight className="text-2xl" />
              </div>
              <div>
                <Link onClick={handleClick} to="/translation">Translation Service</Link>
              </div>
            </div>
          </div>
          <div className="relative flex flex-col gap-7 z-40">
            <div className="pt-5 w-full font-bold">
              <h1>Contact info</h1>
              <div className="absolute w-full">
                <div className="h-[3px] w-[20%] md:w-[50%] bg-blue-500"></div>
                <div className=" md:h-[3px] bg-white"></div>
              </div>
            </div>
            <div className="flex font-normal">
              <div>
                <FaPhoneAlt className="text-2xl" />
              </div>
              <div>
                <h1>00971525453323</h1>
              </div>
            </div>
            <div className="flex font-normal">
              <div>
                <MdEmail className="text-2xl" />
              </div>
              <div>
                <h1>Newguideb2b@gmail.com</h1>
              </div>
            </div>
            <div className="flex font-normal">
              <div>
                <MdLocationPin className="text-2xl" />
              </div>
              <div>
                <h1>Shop S20, Arzoo Building Next to Al Twar Center Al Qusais, Dubai-UAE</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="relative w-[75%] md:w-[40%] z-40 mt-10 mx-auto rounded-s-full rounded-e-full flex items-center justify-center md:py-0 px-3 md:px-0 py-4 md:h-[50px] bg-[#254E94]">
        <h1 className="text-white text-[12px] md:text-[16px] font-bold">
          © 2024 NEWGUIDE All rights reserved.
        </h1>

      </div>
    </div>
  );
};

export default Footer;

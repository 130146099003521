import React, { useState, useEffect } from "react";
import bg1 from "./images/bg.png";
import bg2 from "./images/bg1.png";
import bg3 from "./images/1.png";
import bg4 from "./images/3.png";
import Company from "../company/Company";
import { MdKeyboardArrowRight } from "react-icons/md";

const images = [bg1, bg2, bg3, bg4];
const texts = [
  { title: "Ready. Setup. Go", subtitle: "START A BUSINESS FIRM", desc: "" },
  {
    title: "WITH PROFESSIONAL HELP",
    subtitle: "ATTESTATION IN DUBAI",
    desc: "SETUP CONSULTANCY",
  },
  {
    title: "TRANSLATION SERVICE",
    subtitle: "SMOOTH AND FAST PROCESS",
    desc: "SERVICE WORTH AED 1,750",
  },
  {
    title: "QUALITY IS MAINTAINED",
    subtitle: "OWNERSHIP IS ALLOWED",
    desc: "OPEN TRADING FIRM @ 100% OWNERSHIP",
  },
];

const Main = () => {
  const [currentStep, setCurrentStep] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const windowHeight = window.innerHeight;

      // Calculate the total scrollable height of the sticky section
      const totalScrollableHeight = windowHeight * images.length;

      // Calculate the current step based on the scroll position
      const step = Math.min(
        images.length - 1,
        Math.floor((scrollPosition / totalScrollableHeight) * images.length)
      );

      setCurrentStep(step);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <main className="w-full h-full ">
      {/* Sticky Image Section */}
      <div className="w-full h-[400vh] ">
        <div className="sticky top-0 w-full h-[100vh] bg-black opacity-100  ">
          <div
            className="w-full h-full "
            style={{
              backgroundImage: `url(${images[currentStep]})`,
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          >
            <div className="absolute inset-0 bg-black opacity-50 "></div>
            {/* Progress Bar */}
            <div className="absolute top-9 right-10 text-white  font-bold mb-2">
              0 {currentStep + 1}
            </div>
            <div className="absolute top-[10%] right-10 w-4 h-[50vh] bg-gray-300 rounded-full overflow-hidden">
              <div
                className="w-full bg-blue-500 transition-all duration-300"
                style={{
                  height: `${((currentStep + 1) / images.length) * 100}%`,
                }}
              ></div>
            </div>
            <div className="absolute bottom-[35%] right-10 text-white  font-bold mb-2">
              05
            </div>
            <div className="absolute top-[20%] left-[5%] text-white  font-bold mb-2">
              <div className="grid gap-3">
                <div className="md:text-xl">
                  <h1>{texts[currentStep].title}</h1>
                </div>
                <div className="md:text-4xl font-semibold">
                  <h1>{texts[currentStep].subtitle}</h1>
                </div>
                <div className="md:text-xl font-semibold">
                  <h1>{texts[currentStep].desc}</h1>
                </div>
              </div>
            </div>
            <div className="absolute top-[50%] left-[5%] text-white  font-bold mb-2 uppercase md:text-2xl">
              <div className="flex items-center gap-4">
                <div>
                  <h1>Get Quotation</h1>
                </div>
                <div className="md:w-[40px] md:h-[40px] bg-white rounded-full text-black flex items-center justify-center">
                  <MdKeyboardArrowRight />
                </div>
              </div>
            </div>
            <div className="absolute md:grid hidden bottom-0 w-full">
              <div className="flex w-full justify-between">
                <div className="ml-[5%] w-[300px] xl:w-[400px] h-[184px] bg-white/60 text-[30px] ps-4 pt-6 font-semibold">
                  <p>
                    wide range of expert to serve for <br></br> various
                    standards
                  </p>
                </div>
                <div className="flex w-[300px] xl:w-[400px] h-[184px] bg-white/60 text-[24px] ps-4 pt-6 font-semibold">
                  <div className="grid w-[60%]">
                    
                      <p className="text-[20px]">Next</p>
                    
                    <div className="mt-[-30px]">
                      <p>Attestation in Dubai</p>
                    </div>
                    <div className="mt-[-30px]">
                      <p>with porfessionals</p>
                    </div>
                  </div>
                  <div className="xl:flex  hidden">
                    <img src={bg3} alt="bg3" className="w-[150px] h-[150px]"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Next Content Section */}
      <div className="w-full h-full bg-white overflow-hidden">
        <Company />
        {/* Add more content here */}
      </div>
    </main>
  );
};

export default Main;

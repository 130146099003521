import React, { useState } from "react";
import img1 from "./images/img1.svg";
import img2 from "./images/img2.svg";
import img3 from "./images/img3.svg";
import img4 from "./images/img4.svg";
import Logo from "./images/Logo.png";
import tiktok from "./images/tiktok.svg";
import insta from "./images/insta.svg";
import { FaBars } from "react-icons/fa";
import { Link } from "react-router-dom";
import { RiArrowDropDownLine } from "react-icons/ri";
import { ImCross } from "react-icons/im";

const Nav = () => {
  const [aside, setAside] = useState(false);
  const [toggle, setToggle] = useState(false);
  return (

    <nav className="relative h-[80px] xl:h-[180px] ">

    {/*------ Nav --------- */}
      
      <div className="container mx-auto hidden md:grid md:grid-cols-5 items-center md:text-[12px] lg:text-sm xl:text-lg">
         
          <img src={Logo}  className="xl:w-[120px] xl:h-[140px] lg:w-[70px] w-[50px]"  alt="image"/>
        
        <div className="flex xl:items-start items-center gap-2 font-medium">

            <img src={img4}  className="md:w-[30px] lg:w-[50px] xl:w-[25px] xl:h-[25px]" alt="image1"/>   
            <p>00971525453323</p>          
        </div>

        <div className="flex items-center lg:py-0 py-5 gap-2 font-medium">
            <img src={img3} className="md:w-[30px] lg:w-[50px] xl:w-[25px] xl:h-[25px]" alt="image1" />
            <p className="text-[16px]">
              Shop S20 Azroo building next to AL Twar Center
              <br></br> AL Qusais , Dubai-UAE</p>
        </div>

        <div className="flex gap-2 font-medium">
            <img src={img2} className="md:w-[30px] lg:w-[50px] xl:w-[25px] xl:h-[25px]" alt="image1" />
            <p className="text-[15px]">Newguideb2b@gmail.com</p>
        </div>

        <div className="flex gap-2 font-medium">
            <img src={img1} className="md:w-[30px] lg:w-[50px] xl:w-[25px] xl:h-[25px]" alt="image1" />
            <p>+971525453323</p>
        </div>
      </div>


      <div className="lg:hidden absolute flex items-center justify-between w-full" onClick={() => setAside(true)} >

        <img src={Logo} className="xl:w-[208px] xl:h-[229px] lg:w-[120px] w-[70px]" alt="image"/>
        <FaBars className="text-lg cursor-pointer mr-4" />
      </div>

      <aside
        className={` ${
          aside ? "translate-x-0" : "translate-x-[-100%] "
        } duration-1000  bg-gray-300 text-gray-700  w-full grid lg:hidden relative z-50 items-center justify-between pt-4 pl-4`}
      >
      
        <div className="flex flex-col justify-center gap-2">
      <ImCross onClick={() => setAside(false)} className="cursor-pointer" />
          <div>
            <ul className="flex flex-col justify-center items-center gap-4 capitalize text-[15px] lg:text-base">
              <Link to="/">Home</Link>
              <Link to="/about_us">About Us</Link>
              <li>services</li>
              <Link to="/contact_us">contact us</Link>
            </ul>
          </div>


          <section className="h-60 pt-10" >

            <div className="flex items-center  gap-2 ml-4 mb-4" >
            <img src={img4} className="w-[20px] md:w-[30px] lg:w-[50px] xl:w-[83px]  xl:h-[83px]" alt="image1"/>        
            <p>00971525453323</p>
            </div>

            <div className="flex items-center  gap-2 ml-4 mb-4" >
            <img src={img3} className="w-[20px] md:w-[30px] lg:w-[50px] xl:w-[83px]  xl:h-[83px]" alt="image1"/>        
            <p>Shop S20 Azroo building next to AL Twar
            Center AL Qusais , Dubai-UAE</p>
            </div>

            <div className="flex items-center  gap-2 ml-4 mb-4" >
            <img src={img2} className="w-[20px] md:w-[30px] lg:w-[50px] xl:w-[83px]  xl:h-[83px]" alt="image1"/>        
            <p>Newguideb2b@gmail.com </p>
            </div>

            <div className="flex items-center  gap-2 ml-4" >
            <img src={img1} className="w-[20px] md:w-[30px] lg:w-[50px] xl:w-[83px]  xl:h-[83px]" alt="image1"/>        
            <p>+971525453323</p>
            </div>
            
          </section>
        </div>
      </aside>

      <div className="absolute hidden  z-20 w-full top-[82%] h-[60px]  text-white md:grid items-center ">
        <div className="flex items-center h-full bg-[#254E94] rounded-s-full rounded-e-full mx-5 ">
          <div className="w-[60%] ">
            <ul className="flex justify-around capitalize lg:text-2xl cursor-pointer">
              <Link to="/">Home</Link>
              <Link to="/about_us">About Us</Link>
              <div className="relative">
                <li className="flex items-center justify-center"
                  onMouseEnter={() => setToggle((val) => !val)}
                  onClick={() => setToggle((val) => !val)}
                >
                  services <RiArrowDropDownLine/>
                </li>
                <div
                  className={`${
                    toggle ? "absolute" : "hidden"
                  } w-[10vw] top-[100%] text-black text-base py-2`}
                >
                  <div className="bg-white py-2 px-1">
                    <div className="flex items-center gap-2">
                      <div className="w-3 h-3 bg-black rounded-full"></div>
                      <div>
                        <h1>
                          <Link to="/business_services">Business Service</Link>
                        </h1>
                      </div>
                    </div>
                    <div className="flex items-center gap-2">
                      <div className="w-3 h-3 bg-black rounded-full"></div>
                      <div>
                        <Link to="/visa">Visa Service</Link>
                      </div>
                    </div>
                    <div className="flex items-center gap-2">
                      <div className="w-3 h-3 bg-black rounded-full"></div>
                      <div>
                        <Link to="/online">Online Approvals</Link>
                      </div>
                    </div>
                    <div className="flex items-center gap-2">
                      <div className="w-3 h-3 bg-black rounded-full"></div>
                      <div className="text-sm">
                        <Link to="/attestation">Attestation Service</Link>
                      </div>
                    </div>
                    <div className="flex items-center gap-2">
                      <div className="w-3 h-3 bg-black rounded-full"></div>
                      <div>
                        <Link to="/translation">Translation Service</Link>
                      </div>
                    </div>
                    <div className="flex items-center gap-2">
                      <div className="w-3 h-3 bg-black rounded-full"></div>
                      <div>
                        <Link to="/financial">Financial Service</Link>
                      </div>
                    </div>
                    <div className="flex items-center gap-2">
                      <div className="w-3 h-3 bg-black rounded-full"></div>
                      <div>
                        <Link to="/tax">Tax Consultation</Link>
                      </div>
                    </div>
                    <div className="flex items-center gap-2">
                      <div className="w-3 h-3 bg-black rounded-full"></div>
                      <div>
                        <Link to="/pro">Pro Service</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Link to="/contact_us">contact us</Link>
            </ul>
          </div>
          <div className="w-[20%] flex gap-7 justify-end pr-20">
            <div>
              <a href="https://www.tiktok.com/@newguidebusinessman?_t=8oCheHemMQS&_r=1" target="_blank">
                  <img src={tiktok} alt="tiktok" className="" />
              </a>
          
            </div>
            <div>
               <a href="https://www.instagram.com/newguide2024/?igsh=ZndpZjFkajFyZTV5&utm_source=qr" target="_blank">
                <img src={insta} alt="insta" />
                </a>
            </div>
          </div>
          <div className="flex items-center justify-center cursor-pointer gap-4 w-[20%] bg-[#CACBC5] text-black rounded-e-full h-full text-[20px] lg:text-2xl ">
            <div className="h-6 w-6  bg-[#254E94] rounded-full"></div>
            <div>
              <Link to="/consulting_data">Free Consulting</Link>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Nav;

import React from "react";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import bg from "../Counsling_data/images/izuddin-helmi-adnan-1e71PSox7m8-unsplash.png";
import img1 from "../BusinessService/images/Group 67.svg";
import img2 from "../BusinessService/images/Group 73.svg";
import img3 from "../BusinessService/images/Group 80.svg";
import img4 from "../BusinessService/images/Group 82.svg";
import img5 from "../BusinessService/images/Group 90.svg";
import img6 from "../BusinessService/images/Group 96.svg";
const Visa = () => {

  const whatsapp = () => {
    window.open('https://wa.me/971525453323', '_blank')
  }

  return (
    <div>
      <div
        className="w-full h-[38vh] relative "
        style={{
          backgroundImage: `url(${bg})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="absolute inset-0 bg-black opacity-50 h-full w-full"></div>
        <div className="flex flex-col justify-center items-center gap-4 h-full text-white relative z-10">
          <h1 className="text-3xl font-bold">Visa Services</h1>
          <div className="flex items-center gap-4 font-semibold text-xl">
            <div>
              <h1>Home</h1>
            </div>
            <div>
              <MdKeyboardDoubleArrowRight />
            </div>
            <div>
              <h1>Visa Services</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="grid md:grid-cols-2 lg:grid-cols-3 justify-center mx-auto items-center gap-6  w-full my-7 px-6">
        <div className="flex flex-col border border-gray-500 rounded-3xl shadow-2xl px-8  py-5">
          <img src={img6} className="w-[50px]" alt="img1" />
          <h1 className="text-[#254E94] text-2xl font-semibold">Family Visa</h1>
          <p className="text-base pt-4 pb-7">
          Experience peace of mind knowing that your family visa matters are in capable hands. Let us simplify the 
          journey of bringing your family closer together in Dubai.
          </p>
          <button onClick={whatsapp} className="text-black font-bold bg-gray-400 border w-40 py-3 px-2 border-black rounded-s-full rounded-e-full">
            Whatsapp Now
          </button>
        </div>
        <div className="flex flex-col border border-gray-500 rounded-3xl shadow-2xl px-8  py-5">
          <img src={img4} className="w-[50px]" alt="img1" />
          <h1 className="text-[#254E94] text-2xl font-semibold">
            Golden Visa
          </h1>
          <p className="text-base pt-4 pb-7">
          New Guide is your gateway to seamless document clearance services in Dubai. 
          Discover the exclusive benefits of the Golden Visa program, offering unparalleled access to residency in the vibrant city of Dubai.
          </p>
          <button onClick={whatsapp} className="text-black font-bold bg-gray-400 border w-40 py-3 px-2 border-black rounded-s-full rounded-e-full">
            Whatsapp Now
          </button>
        </div>
        <div className="flex flex-col border border-gray-500 rounded-3xl shadow-2xl px-8  py-5">
          <img src={img3} className="w-[50px]" alt="img1" />
          <h1 className="text-[#254E94] text-2xl font-semibold">
            Employment Visa
          </h1>
          <p className="text-base pt-4 pb-7">
          An Employment Visa is your ticket to the vibrant job market of Dubai, allowing you to work and thrive in this dynamic city. Our dedicated team at New Guide understands 
          the intricacies of the visa application process and is committed to simplifying it for you.
          </p>
          <button onClick={whatsapp} className="text-black font-bold bg-gray-400 border w-40 py-3 px-2 border-black rounded-s-full rounded-e-full">
            Whatsapp Now
          </button>
        </div>
      </div>
      <div className="flex flex-col w-[90%] md:w-[33%] mx-auto mb-10 border border-gray-500 rounded-3xl shadow-2xl px-8  py-5">
        <img src={img2} className="w-[50px]" alt="img1" />
        <h1 className="text-[#254E94] text-2xl font-semibold">
          Visiting Visa
        </h1>
        <p className="text-base pt-4 pb-7">
        Planning a visit to Dubai? Let New Guide simplify your visa process. With our expertise in document clearance, obtaining your visiting visa for Dubai becomes hassle-free. 
        Enjoy the stunning sights, vibrant culture, and luxurious experiences that Dubai has to offer
        </p>
        <button onClick={whatsapp} className="text-black font-bold bg-gray-400 border w-40 py-3 px-2 border-black rounded-s-full rounded-e-full">
          Whatsapp Now
        </button>
      </div>
    </div>
  );
};

export default Visa;

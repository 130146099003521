import React from "react";
import img2 from "../FinancialServices/images/Group 71.svg";
import img1 from "../FinancialServices/images/Group 94.svg";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import bg from "../Counsling_data/images/izuddin-helmi-adnan-1e71PSox7m8-unsplash.png";

const Tax = () => {

  const whatsapp = () => {
    window.open('https://wa.me/971525453323', '_blank')
  }

  return (
    <div>
      <div>
        <div
          className="w-full h-[38vh] relative "
          style={{
            backgroundImage: `url(${bg})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="absolute inset-0 bg-black opacity-50 h-full w-full"></div>
          <div className="flex flex-col justify-center items-center gap-4 h-full text-white relative z-10">
            <h1 className="text-3xl font-bold">Tax Consultation</h1>
            <div className="flex items-center gap-4 font-semibold text-xl">
              <div>
                <h1>Home</h1>
              </div>
              <div>
                <MdKeyboardDoubleArrowRight />
              </div>
              <div>
                <h1>Tax Consultation</h1>
              </div>
            </div>
          </div>
        </div>
        <div className="container w-[80%] mx-auto grid md:grid-cols-2 gap-6   my-14 ">
          <div className=" flex flex-col border border-gray-500 rounded-3xl shadow-2xl px-8  py-5">
            <img src={img1} className="w-[50px]" alt="img1" />
            <h1 className="text-[#254E94] text-2xl font-bold">
              Tax Consulting
            </h1>
            <p className="text-base pt-4 pb-7">
            Dubai is one of the leading international financial centres and among one of the
             fastest growing cities in the world.
            </p>
            <button onClick={whatsapp} className="text-black font-bold bg-gray-400 border w-40 py-3 px-2 border-black rounded-s-full rounded-e-full">
              Whatsapp Now
            </button>
          </div>
          <div className="flex flex-col border border-gray-500 rounded-3xl shadow-2xl px-8  py-5">
            <img src={img2} className="w-[50px]" alt="img1" />
            <h1 className="text-[#254E94] text-2xl font-bold">
              VAT Registration
            </h1>
            <p className="text-base pt-4 pb-7">
            We help organizations across private, public, and social sectors create on the Change that Matters most to them.
            </p>
            <button onClick={whatsapp} className="text-black font-bold bg-gray-400 border w-40 py-3 px-2 border-black rounded-s-full rounded-e-full">
              Whatsapp Now
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tax;

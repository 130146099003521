import "./App.css";
import Nav from "./components/Navbar/Nav";
import whatsapp from "./components/Footer/images/Group 220.svg";

import Main from "./components/Content/Main";
import Services from "./components/Services/Services";
import Footer from "./components/Footer/Footer";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Counsling from "./components/Counsling_data/Counsling";
import About from "./components/AboutUS/About";
import ContactUs from "./components/contactUS/ContactUs";
import Business_Service from "./components/BusinessService/Business_Service";
import Financial_services from "./components/FinancialServices/Financial_services";
import Tax from "./components/Tax/Tax";
import Attestaion from "./components/Attestation/Attestaion";
import Translation from "./components/Translation/Translation";
import Visa from "./components/Visa/Visa";
import Online from "./components/Online/Online";
import Pro from "./components/Pro/Pro";
function App() {
  return (
    <>
      <BrowserRouter>
        <Nav />
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Main />
                <Services />
              </>
            }
          />

          <Route path="/consulting_Data" element={<Counsling />} />
          <Route path="/about_us" element={<About />} />
          <Route path="/contact_us" element={<ContactUs />} />
          <Route path="/business_services" element={<Business_Service />} />
          <Route path="/financial" element={<Financial_services />} />
          <Route path="/tax" element={<Tax />} />
          <Route path="/attestation" element={<Attestaion />} />
          <Route path="/translation" element={<Translation />} />
          <Route path="/visa" element={<Visa />} />
          <Route path="/online" element={<Online />} />
          <Route path="/pro" element={<Pro />} />
        </Routes>
        <div
          className="fixed bottom-4 right-4 z-20 cursor-pointer"
          onClick={() => window.open("https://wa.me/971525453323", "_blank")}
        >
          <img
            src={whatsapp}
            alt="whatsapp"
            className="md:w-auto w-[40px] lg:w-[40px]"
          />
        </div>

        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;

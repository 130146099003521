import React from "react";
import bg from "./images/pexels-mustafa-baadshah-1332630981-25309271.png";
import img1 from "./images/img1.png";
import img2 from "./images/img2.png";
import img3 from "./images/img3.png";
import img4 from "./images/img4.png";
import img5 from "./images/img5.png";
import img6 from "./images/img6.png";
import img7 from "./images/img7.png";
import img8 from "./images/img8.png";
import icon from "./images/Group 23.svg";
import { MdKeyboardArrowRight } from "react-icons/md";
import { Link } from "react-router-dom";

const Services = () => {

  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' 
    });
  }

  return (
    <div
      className="w-full h-full relative text-4xl ps-4 pt-6 font-semibold pb-10"
      style={{
        backgroundImage: `url(${bg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="absolute inset-0 bg-white/60"></div>
      <div className="relative z-10">
        <div className=" ps-[5%] flex flex-col gap-10">
          <div className="">
            <div className="grid gap-2 pt-6">
              <h1 className="text-[20px] font-semibold">ABOUT OUR SERVICES</h1>
              <h1 className="text-[30px]  leading-[40px] font-bold">
                BUSINESS SETUP IN DUBAI
              </h1>
              <h1 className="text-[15px] font-semibold leading-5">
                Our Consultants Is One Of The Leading Business Set Up Companies
                In The UAE.<br></br> We Offer Comprehensive Business Formation
                Services To Help Investors And <br></br>Entrepreneurs Establish
                Operations Anywhere In The UAE, Quickly And In A <br></br>{" "}
                Hassle-Free Manner.
              </h1>
            </div>
          </div>

          <div className="grid lg:grid-cols-4 gap-7 text-3xl pe-[5%]">
<Link onClick={handleClick} to="/business_services" >

            <div
              style={{
                backgroundImage: `url(${img1})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
              className="flex items-center justify-center text-white  relative w-full h-[170px]
               rounded-b-rounded-s-3xl rounded-s-3xl rounded-tr   rounded-br-3xl     "
            >
              {" "}
              <div className="flex items-end text-white relative text-[20px] z-30">
                <h1>Business Service</h1>
                <MdKeyboardArrowRight  className="text-[30px]"/>
              </div>
              <div className="absolute top-[-10%] left-[10%] z-30 bg-white p-3 rounded-b-full rounded-s-full rounded-tr">
                <img src={icon} alt="icon" className="w-[30px]"/>
              </div>
              <div className="absolute inset-0 bg-black opacity-50  rounded-b-3xl rounded-s-3xl rounded-tr"></div>
            </div>

</Link>


<Link onClick={handleClick} to="/visa" >
            <div
              style={{
                backgroundImage: `url(${img2})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
              className="flex items-center justify-center text-white  relative w-full h-[170px]
               rounded-b-rounded-s-3xl rounded-s-3xl rounded-tr   rounded-br-3xl      "
            >
              {" "}
              <div className="flex items-end text-white relative text-[20px] z-30">
                <h1>Visa Services</h1>
                <MdKeyboardArrowRight  className="text-[30px]"/>
              </div>
              <div className="absolute top-[-10%] left-[10%] z-30 bg-white p-3 rounded-b-full rounded-s-full rounded-tr">
                <img src={icon} alt="icon" className="w-[30px]"/>
              </div>
              <div className="absolute inset-0 bg-black opacity-50  rounded-b-3xl rounded-s-3xl rounded-tr"></div>
            </div>
</Link>

<Link onClick={handleClick} to="/pro" >

            <div
              style={{
                backgroundImage: `url(${img3})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
              className="flex items-center justify-center text-white  relative w-full h-[170px]
               rounded-b-rounded-s-3xl rounded-s-3xl rounded-tr  rounded-br-3xl       "
            >
              {" "}
              <div className="flex items-end text-white relative text-[20px] z-30">
                <h1>Pro Service</h1>
                <MdKeyboardArrowRight  className="text-[30px]"/>
              </div>
              <div className="absolute top-[-10%] left-[10%] z-30 bg-white p-3 rounded-b-full rounded-s-full rounded-tr">
                <img src={icon} alt="icon" className="w-[30px]"/>
              </div>
              <div className="absolute inset-0 bg-black opacity-50  rounded-b-3xl rounded-s-3xl rounded-tr"></div>
            </div>
</Link>


<Link onClick={handleClick} to="/tax" >

            <div
              style={{
                backgroundImage: `url(${img4})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
              className="flex items-center justify-center text-white  relative w-full h-[170px]
               rounded-b-rounded-s-3xl rounded-s-3xl rounded-tr   rounded-br-3xl      "
            >
              {" "}
              <div className="flex items-end text-white text-[20px] relative z-30">
                <h1>Tax Consultation</h1>
                <MdKeyboardArrowRight  className="text-[30px]"/>
              </div>
              <div className="absolute top-[-10%] left-[10%] z-30 bg-white p-3 rounded-b-full rounded-s-full rounded-tr">
                <img src={icon} alt="icon" className="w-[30px]"/>
              </div>
              <div className="absolute inset-0 bg-black opacity-50  rounded-b-3xl rounded-s-3xl rounded-tr"></div>
            </div>

</Link>


<Link onClick={handleClick} to="/online" >


            <div
              style={{
                backgroundImage: `url(${img5})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
              className="flex items-center justify-center text-white  relative w-full h-[170px]
               rounded-b-rounded-s-3xl rounded-s-3xl rounded-tr   rounded-br-3xl      "
            >
              {" "}
              <div className="flex items-end text-white relative text-[20px] z-30">
                <h1>Online Approvals</h1>
                <MdKeyboardArrowRight  className="text-[30px]"/>
              </div>
              <div className="absolute top-[-10%] left-[10%] z-30 bg-white p-3 rounded-b-full rounded-s-full rounded-tr">
                <img src={icon} alt="icon" className="w-[30px]"/>
              </div>
              <div className="absolute inset-0 bg-black opacity-50  rounded-b-3xl rounded-s-3xl rounded-tr"></div>
            </div>

</Link>


<Link onClick={handleClick} to="/attestation" >

            <div
              style={{
                backgroundImage: `url(${img6})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
              className="flex items-center justify-center text-white  relative w-full h-[170px]
               rounded-b-rounded-s-3xl rounded-s-3xl rounded-tr    rounded-br-3xl     "
            >
              {" "}
              <div className="flex items-end text-white relative text-[20px] z-30">
                <h1>Attestation Services</h1>
                <MdKeyboardArrowRight  className="text-[30px]"/>
              </div>
              <div className="absolute top-[-10%] left-[10%] z-30 bg-white p-3 rounded-b-full rounded-s-full rounded-tr">
                <img src={icon} alt="icon" className="w-[30px]"/>
              </div>
              <div className="absolute inset-0 bg-black opacity-50  rounded-b-3xl rounded-s-3xl rounded-tr"></div>
            </div>

</Link>


<Link onClick={handleClick} to="/financial" >

            <div
              style={{
                backgroundImage: `url(${img7})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
              className="flex items-center justify-center text-white  relative w-full h-[170px]
               rounded-b-rounded-s-3xl rounded-s-3xl rounded-tr   rounded-br-3xl      "
            >
              {" "}
              <div className="flex items-end text-white relat text-[20px] z-30">
                <h1>Financial Service</h1>
                <MdKeyboardArrowRight className="text-[30px]"/>
              </div>
              <div className="absolute top-[-10%] left-[10%] z-30 bg-white p-3 rounded-b-full rounded-s-full rounded-tr">
                <img src={icon} alt="icon" className="w-[30px]"/>
              </div>
              <div className="absolute inset-0 bg-black opacity-50  rounded-b-3xl rounded-s-3xl rounded-tr"></div>
            </div>

</Link>


<Link onClick={handleClick} to="/translation" >

            <div
              style={{
                backgroundImage: `url(${img8})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
              className="flex items-center justify-center text-white  relative w-full h-[170px]
               rounded-b-rounded-s-3xl rounded-s-3xl rounded-tr   rounded-br-3xl      "
            >
              {" "}
              <div className="flex items-end justify-center text-white relative text-[20px] z-30">
                <h1>Translation Services</h1>
                <MdKeyboardArrowRight  className="text-[30px]"/>
              </div>
              <div className="absolute top-[-10%] left-[10%] z-30 bg-white p-3 rounded-b-full rounded-s-full rounded-tr">
                <img src={icon} alt="icon" className="w-[30px]"/>
              </div>
              <div className="absolute inset-0 bg-black opacity-50  rounded-b-3xl rounded-s-3xl rounded-tr"></div>
            </div>

</Link>

          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;

import React from "react";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import bg from "../Counsling_data/images/izuddin-helmi-adnan-1e71PSox7m8-unsplash.png";
import rectangle from "./images/Rectangle 70.svg";
import img1 from "./images/Group 67.svg";
import img2 from "./images/Group 73.svg";
import img3 from "./images/Group 80.svg";
import img4 from "./images/Group 82.svg";
import img5 from "./images/Group 90.svg";
import img6 from "./images/Group 96.svg";

const Business_Service = () => {

  const whatsapp = () => {
    window.open('https://wa.me/971525453323', '_blank')
  }

  return (
    <div>
      <div
        className="w-full h-[38vh] relative "
        style={{
          backgroundImage: `url(${bg})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="absolute inset-0 bg-black opacity-50 h-full w-full"></div>
        <div className="flex flex-col justify-center items-center gap-4 h-full text-white relative z-10">
          <h1 className="text-3xl font-bold">Business Services</h1>
          <div className="flex items-center gap-4 font-semibold text-xl">
            <div>
              <h1>Home</h1>
            </div>
            <div>
              <MdKeyboardDoubleArrowRight />
            </div>
            <div>
              <h1>Business Services</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-6  w-full my-7 px-6">
        <div className="flex flex-col border border-gray-500 rounded-3xl shadow-2xl px-8  py-5">
          <img src={img1} className="w-[50px]" alt="img1" />
          <h1 className="text-[#254E94] text-2xl font-semibold">
            Company Formation
          </h1>
          <p className="text-base pt-4 pb-7">
            New Guide commitment to innovation and sustainability fosters a
            dynamic entrepreneurial spirit, driving growth and prosperity for
            businesses of all sizes
          </p>
          <button  onClick={whatsapp} className="text-black font-bold bg-gray-400 border w-40 py-3 px-2 border-black rounded-s-full rounded-e-full">
            Whatsapp Now
          </button>
        </div>
        <div className="flex flex-col border border-gray-500 rounded-3xl shadow-2xl px-8  py-5">
          <img src={img2} className="w-[50px]" alt="img1" />
          <h1 className="text-[#254E94] text-2xl font-semibold">
            Business Licence
          </h1>
          <p className="text-base pt-4 pb-7">
          New Guide ensures a seamless process for acquiring this essential license. With stringent 
          regulations in place, securing a business license demands meticulous attention to detail and compliance with local laws.
          </p>
          <button  onClick={whatsapp} className="text-black font-bold bg-gray-400 border w-40 py-3 px-2 border-black rounded-s-full rounded-e-full">
            Whatsapp Now
          </button>
        </div>
        <div className="flex flex-col border border-gray-500 rounded-3xl shadow-2xl px-8  py-5">
          <img src={img3} className="w-[50px]" alt="img1" />
          <h1 className="text-[#254E94] text-2xl font-semibold">
            Incorporation
          </h1>
          <p className="text-base pt-4 pb-7">
          Incorporating a business in Dubai can be a complex journey, but with Ajabalarab by your side, 
          the process becomes seamless and hassle-free.
           Our expert team guides you through every step, ensuring compliance with local regulations and maximizing efficiency.
          </p>
          <button  onClick={whatsapp} className="text-black font-bold bg-gray-400 border w-40 py-3 px-2 border-black rounded-s-full rounded-e-full">
            Whatsapp Now
          </button>
        </div>
        <div className="flex flex-col border border-gray-500 rounded-3xl shadow-2xl px-8  py-5">
          <img src={img4} className="w-[50px]" alt="img1" />
          <h1 className="text-[#254E94] text-2xl font-semibold">
            Trade Mark
          </h1>
          <p className="text-base pt-4 pb-7">
            Securing your brand's identity is paramount in today's competitive, market and in Dubai,
            Ajabalarab specializes in guiding business through the process of trademark registration with ease and efficiency
          </p>
          <button  onClick={whatsapp} className="text-black font-bold bg-gray-400 border w-40 py-3 px-2 border-black rounded-s-full rounded-e-full">
            Whatsapp Now
          </button>
        </div>
        <div className="flex flex-col border border-gray-500 rounded-3xl shadow-2xl px-8  py-5">
          <img src={img5} className="w-[50px]" alt="img1" />
          <h1 className="text-[#254E94] text-2xl font-semibold">
            Company Liquidation
          </h1>
          <p className="text-base pt-4 pb-7">
            Company Liquidation process in Dubai, Our expert team navigates the intricate legal and administrative
            requirements seamlessly, ensuring a smooth and efficient liquidation process for businesses
          </p>
          <button  onClick={whatsapp} className="text-black font-bold bg-gray-400 border w-40 py-3 px-2 border-black rounded-s-full rounded-e-full">
            Whatsapp Now
          </button>
        </div>
        <div className="flex flex-col border border-gray-500 rounded-3xl shadow-2xl px-8  py-5">
          <img src={img6} className="w-[50px]" alt="img1" />
          <h1 className="text-[#254E94] text-2xl font-semibold">
            Financial Advices
          </h1>
          <p className="text-base pt-4 pb-7">
          At New Guide, we specialize in document clearance services tailored to your financial needs. Our seasoned experts offer insightful guidance on investment strategies, 
          tax optimization, and financial planning in the dynamic market of Dubai.
          </p>
          <button  onClick={whatsapp} className="text-black font-bold bg-gray-400 border w-40 py-3 px-2 border-black rounded-s-full rounded-e-full">
            Whatsapp Now
          </button>
        </div>
      </div>
    </div>
  );
};

export default Business_Service;

import React from "react";
import img1 from "./images/img1.png";
import img2 from "./images/img2.png";
import img3 from "./images/img3.png";
import img4 from "./images/pexels-mustafa-baadshah-1332630981-25309271.png";
import bg from "../Counsling_data/images/izuddin-helmi-adnan-1e71PSox7m8-unsplash.png";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import Company from "../company/Company";
import { FaStar } from "react-icons/fa";
import { Link } from "react-router-dom";

const About = () => {
  return (
    <div>
      <div
        className="w-full h-[38vh] relative "
        style={{
          backgroundImage: `url(${bg})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="absolute inset-0 bg-black opacity-50 h-full w-full"></div>
        <div className="flex flex-col justify-center items-center gap-4 h-full text-white relative z-10">
          <h1 className="text-3xl font-bold">About US</h1>
          <div className="flex items-center gap-4 font-semibold text-xl">
            <div>
              <h1>Home</h1>
            </div>
            <div>
              <MdKeyboardDoubleArrowRight />
            </div>
            <div>
              <h1>About Us</h1>
            </div>
          </div>
        </div>
      </div>
      <Company />

      <div
        style={{
          backgroundImage: `url(${bg})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
        className="relative w-full  h-full -z-20 pb-20 text-black"
      >
        <div className="grid justify-center text-center text-black gap-2 pt-20 font-bold">
          <div className="flex items-center justify-center">
            <div className="w-28 h-1 bg-black"></div>
            <h1 className="text-[10px] md:text-[22px] font-semibold">
              CLIENT FEEDBACK IS OUR ACHIEVEMENT
            </h1>
            <div className="w-28 h-1 bg-black"></div>
          </div>
          <h1 className="text-[20px] md:text-[34px]  leading-[70px] font-bold">
            CLIENT SATISFACTION OUR GOAL!{" "}
          </h1>
          <h1 className="text-[5px] md:text-[15px] font-semibold leading-5 pb-10">
            New Analytic Tools Can Help Manufacturers Inearnings By Double-Digit{" "}
            <br></br> On It Is A Secure And Simple On-Demand, The Total
            Percentages.
          </h1>
        </div>
        <div className="absolute inset-0 bg-white opacity-65 -z-10 "></div>

        <div className="grid md:grid-cols-3 gap-28 md:gap-7 px-5 mt-10 mb-10">
          <div className="relative border border-black pt-20 pb-3 px-5 bg-white">
            <div className="relative">
              <p className="text-[16px] font-medium text-start">
                New Guide Consultancy is the leading formation experts in the
                UAE. I have been dealing with them and their in-house expertise
                and professional approach is commendable. Their advice is always
                helpful and they partner to provide suitable suggestions for our
                needs.
              </p>
              <div className="absolute w-full top-[105%] h-[3px] bg-black"></div>
            </div>
            <div className="absolute -top-16">
              <img src={img1} alt="img" className="w-[120px]"/>
            </div>
            <div className="flex flex-col gap-3 pt-7">
              <div>
                <h1 className="font-bold">Agnès Félice</h1>
              </div>
              <div className="flex justify-between">
                <div className="font-medium">
                  <h1>costumer manager</h1>
                </div>
                <div className="flex gap-4 ">
                  <FaStar className="text-yellow-400 text-xl" />
                  <FaStar className="text-yellow-400 text-xl" />
                  <FaStar className="text-yellow-400 text-xl" />
                  <FaStar className="text-yellow-400 text-xl" />
                  <FaStar className="text-yellow-400 text-xl" />
                </div>
              </div>
            </div>
          </div>
          <div className="relative border border-black pt-20 pb-3 px-5 bg-white">
            <div className="relative">
              <p className="text-[16px] font-medium text-start">
                New Guide Consultancy is the leading formation experts in the
                UAE. I have been dealing with them and their in-house expertise
                and professional approach is commendable. Their advice is always
                helpful and they partner to provide suitable suggestions for our
                needs.
              </p>
              <div className="absolute w-full top-[105%] h-[3px] bg-black"></div>
            </div>
            <div className="absolute -top-16">
              <img src={img2} alt="img" className="w-[120px]"/>
            </div>
            <div className="flex flex-col gap-3 pt-7">
              <div>
                <h1 className="font-bold">tom john</h1>
              </div>
              <div className="flex justify-between">
                <div className="font-medium">
                  <h1>costumer manager</h1>
                </div>
                <div className="flex gap-4 ">
                  <FaStar className="text-yellow-400 text-xl" />
                  <FaStar className="text-yellow-400 text-xl" />
                  <FaStar className="text-yellow-400 text-xl" />
                  <FaStar className="text-yellow-400 text-xl" />
                  <FaStar className="text-yellow-400 text-xl" />
                </div>
              </div>
            </div>
          </div>
          <div className="relative border border-black pt-20 pb-3 px-5 bg-white">
            <div className="relative">
              <p className="text-[16px] font-medium text-start">
                New Guide Consultancy is the leading formation experts in the
                UAE. I have been dealing with them and their in-house expertise
                and professional approach is commendable. Their advice is always
                helpful and they partner to provide suitable suggestions for our
                needs.
              </p>
              <div className="absolute w-full top-[105%] h-[3px] bg-black"></div>
            </div>
            <div className="absolute -top-16">
              <img src={img3} alt="img" className="w-[120px]"/>
            </div>
            <div className="flex flex-col gap-3 pt-7">
              <div>
                <h1 className="font-bold">Agnès Félice</h1>
              </div>
              <div className="flex justify-between">
                <div className="font-medium">
                  <h1>costumer manager</h1>
                </div>
                <div className="flex gap-4 ">
                  <FaStar className="text-yellow-400 text-xl" />
                  <FaStar className="text-yellow-400 text-xl" />
                  <FaStar className="text-yellow-400 text-xl" />
                  <FaStar className="text-yellow-400 text-xl" />
                  <FaStar className="text-yellow-400 text-xl" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div>
            <div
              className="w-full h-[38vh] relative "
              style={{
                backgroundImage: `url(${bg})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
            >


              <div className="hidden absolute top-[88%] w-[50%] left-[24%] z-10 mx-auto rounded-s-full rounded-e-full md:flex items-center justify-around md:py-4 px-3 md:px-0 py-4 md:h-[70px] bg-[#254E94]">
  <div className="relative z-50">
    <h1 className="text-white text-[12px] md:text-[14px] font-bold">
      Come to New Guide to do the best work.<br />
      <Link to="/contact_us" className="cursor-pointer border-b border-white">
        Get in touch
      </Link> with us or find an office closest <br />
      to you.
    </h1>
  </div>
  <div className="relative z-50 bg-gray-300 rounded-s-full rounded-e-full py-2 px-4">
    <Link to="/" className="cursor-pointer">
      Let's Get To Work
    </Link>
  </div>
</div>



              <div className="absolute inset-0 bg-black opacity-50 h-full w-full"></div>
              <div className="hidden md:flex flex-col justify-center items-center gap-4 h-full text-white relative z-10">
                <h1 className="text-3xl font-bold">
                  GET READY FOR FREE CONSULTATION?
                </h1>
                <div className="flex items-center gap-4 text-center font-semibold text-[16px]">
                  <h1>
                    New Research Reveals The Barriers Black Talent In The
                    Industry Face, The <br></br> Economic Fallout, And Solutions
                    For Creating A More Inclusive, Equitable <br></br>{" "}
                    Workplace., Gain Insight On Progress And Lessons Learned So
                    Far.
                  </h1>
                </div>
              </div>
            </div>
            <div className="hidden md:grid justify-center text-center text-black gap-2 pt-20 font-bold">
              <div className="flex items-center justify-center">
                <div className="w-28 h-1 bg-black"></div>
                <h1 className="text-[10px] md:text-[25px] font-semibold">
                  OUR FACTS
                </h1>
                <div className="w-28 h-1 bg-black"></div>
              </div>
              <h1 className="text-[20px] md:text-[30px]  leading-[70px] font-bold">
                SOME AWESOME FACTS WE HAVE.{" "}
              </h1>
            </div>
            <div className="grid md:grid-cols-3 gap-7 px-[5%]">
              <div className="border bg-white border-black text-center grid justify-center items-center py-3 px-4">
                <h1 className="text-3xl font-bold">460+</h1>
                <h1 className="text-lg font-medium">
                  Working more than jobs <br></br> with offices in 65+
                  countries.
                </h1>
              </div>
              <div className="border bg-white border-black text-center grid justify-center items-center py-5 px-7">
                <h1 className="text-3xl font-bold">20 M</h1>
                <h1 className="text-lg font-medium">
                  Invested in research on <br></br> diversity
                  and inclusion
                </h1>
              </div>
              <div className="border bg-white border-black text-center grid justify-center items-center py-5 px-7">
                <h1 className="text-3xl font-bold">1560+</h1>
                <h1 className="text-lg font-medium">
                Successful jobs reviews from <br/>our worldwide clients.
                </h1>
              </div>
              <div className="border bg-white border-black text-center grid justify-center items-center py-5 px-7">
                <h1 className="text-3xl font-bold">95%</h1>
                <h1 className="text-lg font-medium">
                Renewable electricity <br/> (target 100% by 2025)
                </h1>
              </div>
              <div className="border bg-white border-black text-center grid justify-center items-center py-5 px-7">
                <h1 className="text-3xl font-bold">100%</h1>
                <h1 className="text-lg font-medium">
                Score for equality in <br/> the Rights Campaign.
                </h1>
              </div>
              <div className="border bg-white border-black text-center grid justify-center items-center py-5 px-7">
                <h1 className="text-3xl font-bold">460+</h1>
                <h1 className="text-lg font-medium">
                Active team members are <br/> giving support.
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;

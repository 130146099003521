import React from "react";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import bg from "../Counsling_data/images/izuddin-helmi-adnan-1e71PSox7m8-unsplash.png";
import img1 from "./images/Group 165.svg";
import img2 from "./images/Group 181.svg";
import img3 from "./images/Group 186.svg";
import img4 from "./images/Group 210.svg";
import img5 from "./images/Group 213.svg";
import img6 from "./images/Group 214.svg";
const Pro = () => {
  
  const whatsapp = () => {
    window.open('https://wa.me/971525453323', '_blank')
  }

  return (
    <div>
      <div>
        <div
          className="w-full h-[38vh] relative "
          style={{
            backgroundImage: `url(${bg})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="absolute inset-0 bg-black opacity-50 h-full w-full"></div>
          <div className="flex flex-col justify-center items-center gap-4 h-full text-white relative z-10">
            <h1 className="text-3xl font-bold">Pro Services</h1>
            <div className="flex items-center gap-4 font-semibold text-xl">
              <div>
                <h1>Home</h1>
              </div>
              <div>
                <MdKeyboardDoubleArrowRight />
              </div>
              <div>
                <h1>Pro Services</h1>
              </div>
            </div>
          </div>
        </div>
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6  w-full my-7 px-6">
          <div className="flex flex-col border gap-4 border-gray-500 rounded-3xl shadow-2xl px-8  py-5">
            <img src={img6} className="w-[50px]" alt="img1" />
            <h1 className="text-[#254E94] text-2xl font-semibold">
              Amer & Tasheel Services
            </h1>
            <button onClick={whatsapp}  className="text-black font-bold bg-gray-400 border w-40 py-3 px-2 border-black rounded-s-full rounded-e-full">
              Whatsapp Now
            </button>
          </div>
          <div className="flex flex-col border gap-4 border-gray-500 rounded-3xl shadow-2xl px-8  py-5">
            <img src={img4} className="w-[50px]" alt="img1" />
            <h1 className="text-[#254E94] text-2xl font-semibold">
              Labour & Immigration Works
            </h1>
            <button onClick={whatsapp}  className="text-black font-bold bg-gray-400 border w-40 py-3 px-2 border-black rounded-s-full rounded-e-full">
              Whatsapp Now
            </button>
          </div>
          <div className="flex flex-col border gap-4 border-gray-500 rounded-3xl shadow-2xl px-8  py-5">
            <img src={img3} className="w-[50px]" alt="img1" />
            <h1 className="text-[#254E94] text-2xl font-semibold">
              New & Renewal Visa
            </h1>
            <button onClick={whatsapp}  className="text-black font-bold bg-gray-400 border w-40 py-3 px-2 border-black rounded-s-full rounded-e-full">
              Whatsapp Now
            </button>
          </div>
          <div className="flex flex-col border gap-4 border-gray-500 rounded-3xl shadow-2xl px-8  py-5">
            <img src={img2} className="w-[50px]" alt="img1" />
            <h1 className="text-[#254E94] text-2xl font-semibold">
              Employees Documentation
            </h1>
            <button onClick={whatsapp}  className="text-black font-bold bg-gray-400 border w-40 py-3 px-2 border-black rounded-s-full rounded-e-full">
              Whatsapp Now
            </button>
          </div>
          <div className="flex flex-col border gap-4 border-gray-500 rounded-3xl shadow-2xl px-8  py-5">
            <img src={img1} className="w-[50px]" alt="img1" />
            <h1 className="text-[#254E94] text-2xl font-semibold">
              OHD (Occupation Health Card)
            </h1>
            <button onClick={whatsapp}  className="text-black font-bold bg-gray-400 border w-40 py-3 px-2 border-black rounded-s-full rounded-e-full">
              Whatsapp Now
            </button>
          </div>
          <div className="flex flex-col border gap-4 border-gray-500 rounded-3xl shadow-2xl px-8  py-5">
            <img src={img5} className="w-[50px]" alt="img1" />
            <h1 className="text-[#254E94] text-2xl font-semibold">
              Emirates ID & Medical Typing
            </h1>
            <button onClick={whatsapp}  className="text-black font-bold bg-gray-400 border w-40 py-3 px-2 border-black rounded-s-full rounded-e-full">
              Whatsapp Now
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pro;

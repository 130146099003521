import React from "react"; 
import bg from "./images/izuddin-helmi-adnan-1e71PSox7m8-unsplash.png";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import bg1 from "./images/pexels-mustafa-baadshah-1332630981-25309271.png";
import Contact from "../contactUS/Contact";

const Counsling = () => {
  return (
    <div className="w-screen items-center overflow-x-hidden">      
      <div
        className="w-screen h-[38vh] relative"
        style={{
          backgroundImage: `url(${bg})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="absolute inset-0 bg-black opacity-50 h-full w-full"></div>
        <div className="flex flex-col justify-center items-center gap-4 h-full text-white relative z-10">
          <h1 className="text-3xl font-bold">Consulting Data</h1>
          <div className="flex items-center gap-4 font-semibold text-xl">
            <div>
              <h1>Home</h1>
            </div>
            <div>
              <MdKeyboardDoubleArrowRight />
            </div>
            <div>
              <h1>Consulting Data</h1>
            </div>
          </div>
        </div>
      </div>
      <div
        className="w-screen h-full bg-gray-400 relative"
        style={{
          backgroundImage: `url(${bg1})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="absolute inset-0 bg-white opacity-70 h-full w-full"></div>
         <div className="relative z-10 w-[70%] xl:h-[80%] 2xl:h-[90%] rounded-3xl md:mx-auto shadow-2xl p-8">
          <Contact/>
        </div> 
      </div>
    </div>
  );
};

export default Counsling;
